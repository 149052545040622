import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  assignOrdenCaja,
  OrdenesPorPagar,
} from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";
import { getListParentCities } from "actions/admin/parentCityAction";

export const useOrderPayable = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue, control } =
    useForm();
  const { totalMonto, disabledBtnPago, listEntregada } = useSelector(
    (state) => state.transportOrders
  );
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { metodo_pago } = useSelector((state) => state.metodoPagos);
  const { listParentCities } = useSelector((state) => state.parentCities);
  const [hidden, setHidden] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setIsVisible(true);

    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por devolver", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (data.ordenes.filter((x) => x.documentacion_id === "").length > 0) {
      toast.error("Complete los datos del listado", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const information = {
      ordenes: data.ordenes,
      rut_remitente: data.ordenes[0].rut_remitente,
      rut_destinatario: data.ordenes[0].rut_destinatario,
      documentacion_id: data.ordenes[0].documentacion_id,
    };

    if (parseInt(information.documentacion_id) === 1) {
      setHidden(true);
      setInfoData(information);
    } else {
      await funcFacturar(information);
    }
  };

  const funcFacturar = async (information) => {
    setLoading(true);

    let factura = { rut: information?.rut_destinatario };
    if (information?.factura) {
      factura = {
        rut: information?.factura?.rut_factura,
        razon_social: information?.factura?.razon_social_factura,
        direccion: information?.factura?.direccion_factura,
        telefono: information?.factura?.contacto_factura,
        email: information?.factura?.email_factura,
        sucursal_id: information?.factura?.ciudad_factura,
        comuna_id: information?.factura?.comuna_factura,
        rubro: information?.factura?.giro_factura,
        city: information?.factura?.city_origin_name,
        office: information?.factura?.office_origin_name,
      };
    }
    const data = {
      ordenes: information.ordenes.map((orden) => orden.orden_flete_id),
      factura,
      rut_remitente: information.rut_remitente,
      rut_destinatario: information.rut_destinatario,
      documentacion_id: information.documentacion_id,
    };

    const response = await dispatch(OrdenesPorPagar(data));
    if (response.status) {
      const body = {
        fecha_entrega: response.fecha_entrega,
        ordens: information.ordenes.map((orden) => orden.orden_flete_id),
      };
      await dispatch(assignOrdenCaja(body));
      setLoading(false);
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      document.getElementById("frmOrdenEntregada").reset();
      return true;
    } else {
      setLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const getDatInitial = async () => {
    await dispatch(
      getListParentCities(companyDataBasic?.country?.id, "active")
    );
  };

  const state = {
    hidden,
    infoData,
    loading,
    disabledBtnPago,
    companyDataBasic,
    totalMonto,
    metodo_pago,
    isVisible,
    listParentCities,
    listEntregada,
  };

  const actions = {
    setHidden,
    setInfoData,
    setLoading,
    addOrdenAction: funcFacturar,
    getDatInitial,
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  };

  return {
    actions,
    formElement,
    state,
  };
};

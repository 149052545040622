import { Fragment, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ModalListado from "./Estados/ModalListado";
import { usePermission } from "hooks/Permission/usePermission";

import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { handlePasteOnlyOrden, validateOnlyOrden } from "utils/validationInput";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";
import { useDateOrder } from "hooks/Orders/useDateOrder";
import ComponentCustomDate from "shared/ComponentCustomDate";

const Sucursales = ({
  initial,
  editOrden,
  formElement,
  actionsGlobal,
  stateOrden,
}) => {
  const { tipoEnvios, listOffices, dataTransportOrder, city_id, office_id } =
    stateOrden;
  const { handleOrderNumberBlur } = actionsGlobal;

  //const { register, errors, reset, getValues, setValue, control } = formElement;
  const { register, errors, control, setValue, getValues } = formElement;

  const { validateDateBlur, restMounth } = useDateOrder();

  const { actions, state } = useTransportOrderOffice();

  const {
    showEstado,
    handleCloseModal,
    loadOffices,
    setShowIsModal,
    setOfficeOrigin,
    setOfficeDestination,
  } = actions;

  const { showIsModal, officeOrigin, officeDestination } = state;

  const { validarPermiso } = usePermission();

  /**useEffect(() => {
    if (
      Object.keys(ordenInfo).length > 0 &&
      initial === false &&
      ordenInfo.sucursal_origen_id !== "" &&
      ordenInfo.sucursal_destino_id !== ""
    ) {
      loadOffices(undefined, "origen", ordenInfo.sucursal_origen_id);
      loadOffices(undefined, "destino", ordenInfo.sucursal_destino_id);
      reset({
        ...getValues(),
        sucursal_origen_id: ordenInfo.sucursal_origen_id,
        oficina_origen_id: ordenInfo.oficina_origen_id,
        sucursal_destino_id: ordenInfo.sucursal_destino_id,
        oficina_destino_id: ordenInfo.oficina_destino_id,
        forma_pago_id: ordenInfo.forma_pago_id,
        documentacion_id: ordenInfo.documentacion_id,
        tipo_envio_id: ordenInfo.tipo_envio_id,
        documentacion_id_pago: ordenInfo?.documentacion_id_pago,
        descuento: ordenInfo.descuento || "No",
      });
    } else {
      if (sucursal_id) {
        loadOffices(undefined, "origen", sucursal_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sucursales, ordenInfo.sucursal_origen_id]);**/

  useEffect(() => {
    if (listOffices?.length > 0) {
      if (editOrden && dataTransportOrder !== null) {
        const resultOrigin = loadOffices(
          undefined,
          "origen",
          dataTransportOrder?.city_origin_id,
          listOffices
        );
        setOfficeOrigin(resultOrigin);
        const resultDestination = loadOffices(
          undefined,
          "destino",
          dataTransportOrder?.city_destination_id,
          listOffices
        );
        setOfficeDestination(resultDestination);

        setValue("office_origin_id", dataTransportOrder?.office_origin_id);
        /**reset({
          ...getValues(),
          sucursal_origen_id: ordenInfo.sucursal_origen_id,
          oficina_origen_id: ordenInfo.oficina_origen_id,
          sucursal_destino_id: ordenInfo.sucursal_destino_id,
          oficina_destino_id: ordenInfo.oficina_destino_id,
          forma_pago_id: ordenInfo.forma_pago_id,
          documentacion_id: ordenInfo.documentacion_id,
          tipo_envio_id: ordenInfo.tipo_envio_id,
          documentacion_id_pago: ordenInfo?.documentacion_id_pago,
          descuento: ordenInfo.descuento || "No",
        });**/
      } else {
        const resultOrigin = loadOffices(
          undefined,
          "origen",
          city_id,
          listOffices
        );

        setOfficeOrigin(resultOrigin);
        if (getValues("city_origin_id") === "") {
          setValue("city_origin_id", city_id);
        }

        if (getValues("office_origin_id") === "") {
          setValue("office_origin_id", office_id);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listOffices,
    city_id,
    office_id,
    dataTransportOrder?.city_destination_id,
    dataTransportOrder?.city_origin_id,
  ]);

  return (
    <Fragment>
      <Row className="bg-gray panel-contenedor--info mb-0 pt-1">
        <Col xs={12}>
          <Form.Row>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="city_origin_id"
                className="pr-0 mb-0"
              >
                <Form.Label>Ciudad de Origen</Form.Label>
                <Controller
                  control={control}
                  name="city_origin_id"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => {
                    return (
                      <select
                        value={props.value}
                        className="form-control"
                        id="city_origin_id"
                        onChange={(event) => {
                          props.onChange(event.target.value);
                          const result = loadOffices(
                            event,
                            "origin",
                            "",
                            listOffices
                          );
                          setOfficeOrigin(result);
                          setValue("office_origin_id", "");
                        }}
                        disabled={!validarPermiso("cambiar_sucursal")}
                      >
                        <option value="">Seleccione</option>
                        {listOffices.length > 0 &&
                          listOffices.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                  defaultValue={""}
                />
                {errors.city_origin_id && (
                  <Form.Text className="text-danger">
                    {errors?.city_origin_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="order_number"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>O. Manual N°</Form.Label>
                <Form.Control
                  type="text"
                  name="order_number"
                  autoFocus
                  //defaultValue={ordenInfo.order_number}
                  autoComplete="off"
                  readOnly={editOrden}
                  maxLength={20}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                  onBlur={(event) => handleOrderNumberBlur(event)}
                  onKeyPress={(event) => validateOnlyOrden(event)}
                  onPaste={(event) => handlePasteOnlyOrden(event)}
                />
                {errors.order_number && (
                  <Form.Text className="text-danger">
                    {errors?.numero_orden?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="office_origin_id"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Comuna de Origen</Form.Label>
                <Controller
                  control={control}
                  name="office_origin_id"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => {
                    return (
                      <select
                        value={props.value}
                        className="form-control"
                        id="office_origin_id"
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                        disabled={!validarPermiso("cambiar_sucursal")}
                      >
                        <option value="">Seleccione</option>
                        {officeOrigin.length > 0 &&
                          officeOrigin.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                  defaultValue={""}
                />
                {errors.office_origin_id && (
                  <Form.Text className="text-danger">
                    {errors?.office_origin_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="city_destination_id"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Sucursal destino</Form.Label>
                <Form.Control
                  as="select"
                  name="city_destination_id"
                  onChange={(event) => {
                    const result = loadOffices(
                      event,
                      "destination",
                      "",
                      listOffices
                    );
                    setOfficeDestination(result);
                    setValue("office_destination_id", "");
                  }}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {listOffices.length > 0 &&
                    listOffices.map((sucursal) => (
                      <option key={sucursal.id} value={sucursal.id}>
                        {sucursal.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.city_destination_id && (
                  <Form.Text className="text-danger">
                    {errors?.city_destination_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="office_destination_id"
                className="pl-0 mb-0"
              >
                <Form.Label>Comuna destino</Form.Label>
                <Form.Control
                  as="select"
                  name="office_destination_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {officeDestination.length > 0 &&
                    officeDestination.map((destino) => (
                      <option key={destino.id} value={destino.id}>
                        {destino.name}
                      </option>
                    ))}
                </Form.Control>
                {errors.office_destination_id && (
                  <Form.Text className="text-danger">
                    {errors?.office_destination_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row className="mt-1">
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="fecha_orden"
                className="pr-0 mb-0"
              >
                <Form.Label>Fecha Orden</Form.Label>
                <Controller
                  control={control}
                  name="fecha_orden"
                  render={(props) => {
                    return (
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        onChange={(event) => {
                          props.onChange(event);
                        }}
                        selected={props.value}
                        className="form-control"
                        readOnly={!validarPermiso("cambiar_sucursal")}
                        id="fecha_orden"
                        maxDate={new Date()}
                        minDate={restMounth()}
                        locale="es"
                        onCalendarClose={() =>
                          validateDateBlur(props, props.value, "#fecha_orden")
                        }
                        autoComplete="off"
                        customInput={<ComponentCustomDate />}
                      />
                    );
                  }}
                />
                {errors.fecha_orden && (
                  <Form.Text className="text-danger">
                    {errors?.fecha_orden?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="shipment_type_id"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Entrega en</Form.Label>
                <Form.Control
                  as="select"
                  name="shipment_type_id"
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                >
                  <option value="">Seleccione</option>
                  {tipoEnvios.length > 0 &&
                    tipoEnvios.map((entrega) => (
                      <option key={entrega.id} value={entrega.id}>
                        {entrega.description}
                      </option>
                    ))}
                </Form.Control>
                {errors.shipment_type_id && (
                  <Form.Text className="text-danger">
                    {errors?.shipment_type_id?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="user_name"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  ref={register}
                  //defaultValue={ordenInfo.user_name}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="estado_orden"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  type="text"
                  name="estado_orden"
                  ref={register}
                  //defaultValue={ordenInfo.estado_orden}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="nro_nomina"
                className="pr-1 pl-0 mb-0"
              >
                <Form.Label>Nómina</Form.Label>
                <Form.Control
                  type="text"
                  name="nro_nomina"
                  ref={register}
                  //defaultValue={ordenInfo.nro_nomina}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col lg md={4} sm={4}>
              <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="mt-4 mb-0"
              >
                <Button
                  variant="warning"
                  type="button"
                  block
                  onClick={(event) => showEstado(event)}
                  disabled={!editOrden}
                >
                  Tracking
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Col>
      </Row>
      {showIsModal && (
        <ModalListado
          showIsModal={showIsModal}
          setShowIsModal={setShowIsModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Fragment>
  );
};

export default Sucursales;

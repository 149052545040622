import API from "config/config-api";
import APIV1 from "config/api";
import {
  BUSQUEDA_CLIENTE,
  IS_LOADING_DATA,
  HISTORICO_FACTURAS,
  SELECTED_OPTION,
  HIDDEN_BOTONES,
  ADD_DOCUMENT_PENDIENT,
  ADD_PAYMENT_METHODS,
  SEARCH_CLIENTE_PENDIENT,
  SEARCH_INVOICE_BY_PARAMETERS,
  LOADING_SEARCH_PAYMENT,
} from "types/facturacionType";
import { formatMonto, formatFloat } from "config/formatInput";
import { validarPermiso } from "utils/Permission/checkPermission";
import { v4 as uuidv4 } from "uuid";
//import { formatNumberCurrency } from "utils/validationInput";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const resetListOrdenes = (body) => async (dispatch, getState) => {
  const payload = {
    listado: [],
    cliente: {
      rut: "",
      nombre: "",
    },
    busqueda: {},
    opcion: "default",
    isCargando: false,
    resultView: true,
  };
  dispatch(requestSuccess(BUSQUEDA_CLIENTE, payload));
};

export const getListadoOrdenes = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "facturacion_cliente")) {
      return {
        status: false,
        message: "No tiene permiso para exportar la información",
      };
    } else {
      dispatch(requestSuccess(IS_LOADING_DATA, true));

      let response = await API.post(endPoints.invoice.searchMonth, body);
      const { status, message, data } = response.data;
      if (status) {
        const resultView = data.ordenes.length > 0 ? false : true;
        const payload = {
          listado: data.ordenes,
          cliente: {
            rut: data.rut,
            nombre: data.nombre,
          },
          busqueda: body,
          opcion: body.facturacion_mes,
          isCargando: false,
          resultView,
        };

        dispatch(requestSuccess(BUSQUEDA_CLIENTE, payload));
      } else {
        const payload = {
          listado: [],
          cliente: {
            rut: "",
            nombre: "",
          },
          busqueda: body,
          opcion: body.facturacion_mes,
          isCargando: false,
          resultView: true,
        };
        dispatch(requestSuccess(BUSQUEDA_CLIENTE, payload));
      }

      return { status, message, data };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addFacturacion = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "facturacion_cliente")) {
      return {
        status: false,
        message: "No tiene permiso para exportar la información",
      };
    } else {
      const { listOrdenes, busqueda } = getState().facturacion;

      const dataSend = {
        listado: listOrdenes.map((orden) => orden.id),
        desde: busqueda.desde,
        hasta: busqueda.hasta,
        codigo_interno: busqueda.codigo_interno,
        forma_pago_id: listOrdenes[0]?.forma_pago_id,
        nro_documento: body?.numero_documento ?? "",
      };

      let response = await API.post(endPoints.invoice.add, dataSend);
      const { status, message, data } = response.data;
      return { status, message, data };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const getHistorico = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "facturacion_cliente")) {
      return {
        status: false,
        message: "No tiene permiso para exportar la información",
      };
    } else {
      dispatch(requestSuccess(IS_LOADING_DATA, true));

      let response = await API.post(endPoints.invoice.history, body);
      const { status, message, data } = response.data;
      if (status) {
        const resultView = data.ordenes.length > 0 ? false : true;
        const payload = {
          historial: data.ordenes,
          opcion: body.facturacion_mes,
          isCargando: false,
          resultView,
          cliente: {
            rut: data.rut,
            nombre: data.nombre,
          },
        };

        dispatch(requestSuccess(HISTORICO_FACTURAS, payload));
      } else {
        const payload = {
          historial: [],
          opcion: body.facturacion_mes,
          isCargando: false,
          resultView: true,
          cliente: {
            rut: "",
            nombre: "",
          },
        };
        dispatch(requestSuccess(HISTORICO_FACTURAS, payload));
      }

      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const exportarFilePdf = (desde, hasta) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "facturacion_cliente")) {
      return {
        status: false,
        message: "No tiene permiso para exportar la información",
      };
    } else {
      const { listFiltrado, cliente } = getState().facturacion;

      if (listFiltrado.length > 0) {
        const listado_facturas = listFiltrado.map((item) => {
          return {
            numero_folio: item.numero_folio,
            fecha_orden: item.fecha_orden,
            fecha_entrega: item.fecha_entrega,
            fecha_factura: item.fecha_factura,
            total: item.total,
            numero_orden: item.numero_orden,
          };
        });
        const total = listado_facturas.reduce(
          (acumulado, item) => acumulado + parseFloat(formatFloat(item.total)),
          0
        );

        let data = {
          cliente: cliente.nombre,
          ordens: listado_facturas,
          fecha_desde: desde,
          fecha_hasta: hasta,
          totales: formatMonto(total),
        };

        let response = await APIV1.putFile(endPoints.invoice.exportPDF, {
          list_factura: data,
        });

        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.download = "facturas_cliente.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.close();
        });
        return { status: true, message: "" };
      }
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const exportarFileExcel =
  (desde, hasta) => async (dispatch, getState) => {
    try {
      const { listFiltrado, cliente } = getState().facturacion;

      if (listFiltrado.length > 0) {
        const listado_facturas = listFiltrado.map((item) => {
          return {
            numero_folio: item.numero_folio,
            fecha_orden: item.fecha_orden,
            fecha_entrega: item.fecha_entrega,
            fecha_factura: item.fecha_factura,
            total: item.total?.replaceAll(".", ""),
            numero_orden: item.numero_orden,
          };
        });
        let data = {
          cliente: cliente.nombre,
          ordens: listado_facturas,
          fecha_desde: desde,
          fecha_hasta: hasta,
        };

        let response = await APIV1.putFile(endPoints.invoice.exportExcel, {
          list_factura: data,
        });

        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "facturas_cliente.xlsx";
          a.click();
        });
        return { status: false, message: "" };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const selectedOption = () => (dispatch, getState) => {
  const payload = { ordenes: [], resultView: true, historial: [] };
  dispatch(requestSuccess(SELECTED_OPTION, payload));
};

export const hiddenBotones = (listado, search) => (dispatch, getState) => {
  const rows = listado.filter(
    (item) =>
      item.numero_folio &&
      item.numero_folio.toString().toLowerCase().includes(search.toLowerCase())
  );
  const payload = {
    hiddenBtn: rows.length > 0 ? true : false,
    listFiltrado: rows,
  };
  dispatch(requestSuccess(HIDDEN_BOTONES, payload));
};

export const searchDocument = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.invoice.tributary, body);
    const { status, message, data } = response.data;
    if (status) {
      let url_documento = data;
      if (data.includes("http")) {
        url_documento = data.replace("http", "https");
      }

      let a = document.createElement("a");
      a.href = url_documento;
      a.download = `documento_${body.numero_documento}.pdf`;
      a.click();
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const toogleDocumentPayment = (body, option) => (dispatch, getState) => {
  try {
    const { cancelationPayment, listDocumentClient } = getState().facturacion;

    let { listDocumentPendient, listDetailPayment } = cancelationPayment;

    let listDocument = [];

    if (option === "add") {
      let verifiedDocument = listDocumentPendient.find(
        (item) => item.id?.toString() === body.id?.toString()
      );

      if (!verifiedDocument) {
        let documentSelected = listDocumentClient.find(
          (item) => item.id?.toString() === body.id?.toString()
        );
        listDocument = [...listDocumentPendient, documentSelected];
      } else {
        return {
          status: false,
          message: "El documento ya se encuentra en la lista",
        };
      }
    } else if (option === "delete") {
      listDocument = listDocumentPendient.filter(
        (item) => item.id?.toString() !== body.id?.toString()
      );
    }

    let total = listDocument?.reduce(
      (acumulado, documento) => acumulado + parseFloat(documento.monto),
      0
    );
    let totalRestante = listDocument?.reduce(
      (acumulado, documento) =>
        acumulado + parseFloat(documento.monto_restante),
      0
    );
    let totalCta = listDetailPayment?.reduce(
      (acumulado, documento) => acumulado + parseFloat(documento.monto),
      0
    );

    let remaining_amount = totalRestante - totalCta;

    const payload = { listDocument, total, remaining_amount };

    dispatch(requestSuccess(ADD_DOCUMENT_PENDIENT, payload));

    return { status: true, message: "", total, remaining: remaining_amount };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const toogleDetailPaymentMethods =
  (body, option) => (dispatch, getState) => {
    try {
      const { cancelationPayment } = getState().facturacion;

      let { listDetailPayment, listDocumentPendient } = cancelationPayment;

      let listPaymentMethods = [];

      if (option === "add") {
        let payment = {
          ...body,
          id: uuidv4(),
        };
        listPaymentMethods = [...listDetailPayment, payment];
      } else if (option === "delete") {
        listPaymentMethods = listDetailPayment.filter(
          (item) => item.id !== body.id
        );
      }

      let total = listDocumentPendient?.reduce(
        (acumulado, documento) =>
          acumulado + parseFloat(documento.monto_restante),
        0
      );
      let totalCta = listPaymentMethods?.reduce(
        (acumulado, documento) => acumulado + parseFloat(documento.monto),
        0
      );

      let remaining_amount = total - totalCta; // parseFloat(total-parseFloat(totalCta).toFixed(3)).toFixed(3)

      const payload = { listPaymentMethods };

      dispatch(requestSuccess(ADD_PAYMENT_METHODS, payload));

      return { status: true, message: "", remaining: remaining_amount };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getDocumentPendientByClient =
  (internal_code) => async (dispatch, getState) => {
    try {
      const { configuracion } = getState().config;

      if (!validarPermiso(configuracion, "crear_pagos_factura")) {
        return {
          status: false,
          message: "No tiene permiso para buscar cliente y registrar pagos",
        };
      } else {
        let response = await API.get(
          endPoints.invoice.searchCustomer(internal_code)
        );
        const { status, message, data } = response.data;
        let dataClient = data;
        let listDocument = [];

        if (typeof data?.listDocumentPendient !== "undefined") {
          listDocument = data?.listDocumentPendient.map((item) => {
            return {
              ...item,
              monto: item?.monto,
              monto_restante: item?.monto_restante,
            };
          });
        }

        let payload = {
          data: {
            full_name:
              response.data !== null &&
              typeof dataClient?.full_name === "undefined"
                ? ""
                : dataClient?.full_name,
            rut:
              response.data !== null && typeof dataClient?.rut === "undefined"
                ? ""
                : dataClient?.rut,
            telephone:
              response.data !== null &&
              typeof dataClient?.telephone === "undefined"
                ? ""
                : dataClient?.telephone,
            email:
              response.data !== null && typeof dataClient?.email === "undefined"
                ? ""
                : dataClient?.email,
            listDetailPayment: [],
            listDocumentPendient: [],
            total: 0,
          },
          listDocumentClient: listDocument,
        };

        dispatch(requestSuccess(SEARCH_CLIENTE_PENDIENT, payload));

        let statusResult = status;
        let messageResult = message;

        if (status) {
          statusResult =
            data?.listDocumentPendient?.length === 0 ? false : true;
          messageResult =
            data?.listDocumentPendient?.length === 0
              ? "El cliente no tiene documento de pago pendiente"
              : "";
        }

        return { status: statusResult, message: messageResult, data };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const savePaymentClient = (body) => async (dispatch, getState) => {
  try {
    const { configuracion } = getState().config;

    if (!validarPermiso(configuracion, "crear_pagos_factura")) {
      return {
        status: false,
        message: "No tiene permiso para registrar pagos",
      };
    } else {
      const { cancelationPayment } = getState().facturacion;

      let totalPayment = cancelationPayment?.listDetailPayment.reduce(
        (acumulado, documento) => acumulado + parseFloat(documento.monto),
        0
      );

      let remaining_amount = cancelationPayment?.total - totalPayment;

      let data = {
        internal_code: body?.internal_code,
        cancellation_date: body?.cancellation_date,
        total: cancelationPayment?.total,
        listDocumentPendient: cancelationPayment?.listDocumentPendient,
        listDetailPayment: cancelationPayment?.listDetailPayment,
        payment_type: remaining_amount === 0 ? "Total" : "Parcial",
      };

      let response = await API.post(endPoints.invoice.payments, data);
      const { status, message } = response.data;
      if (status) {
        let dataPayment = {
          full_name: "",
          rut: "",
          telephone: "",
          email: "",
          cancellation_date: new Date(),
          internal_code: "",
          payment_type: "Total",
          amount: 0,
          remaining_amount: 0,
          listDetailPayment: [],
          listDocumentPendient: [],
          total: 0,
        };

        const payload = {
          data: dataPayment,
          listDocumentClient: [],
        };

        dispatch(requestSuccess(SEARCH_CLIENTE_PENDIENT, payload));
      }

      return { status, message };
    }
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchListInvoices =
  (body, page) => async (dispatch, getState) => {
    try {
      const { configuracion } = getState().config;

      if (!validarPermiso(configuracion, "busqueda_factura_corriente")) {
        return {
          status: false,
          message: "No tiene permiso para buscar facturas",
        };
      } else {
        let payload = { resultList: [], isLoading: true, dataSearch: body };

        dispatch(requestSuccess(SEARCH_INVOICE_BY_PARAMETERS, payload));

        let response = await API.post(
          endPoints.invoice.listPayments(page),
          body
        );
        const { status, message, data, total_paginate } = response.data;
        payload = {
          resultList: data,
          isLoading: false,
          dataSearch: { ...body, isChange: true },
        };

        dispatch(requestSuccess(SEARCH_INVOICE_BY_PARAMETERS, payload));

        return {
          status,
          message,
          countPaginate: total_paginate,
        };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const resetListInvoices =
  (value = true, site = "same") =>
  (dispatch, getState) => {
    const { dataSearch, resultListInvoice } = getState().facturacion;

    let payload = {
      resultList:
        dataSearch?.isChange && site === "same" ? resultListInvoice : [],
      isLoading: value,
      dataSearch:
        site === "other"
          ? {
              estatus_id: 22,
              from_date: new Date(),
              to_date: new Date(),
              isChange: false,
            }
          : dataSearch,
    };

    dispatch(requestSuccess(SEARCH_INVOICE_BY_PARAMETERS, payload));
    return {
      status: true,
      data: dataSearch,
    };
  };

export const exportFileExcel =
  (nameFile, type, isViewDetail, title) => async (dispatch, getState) => {
    try {
      const { configuracion } = getState().config;

      if (!validarPermiso(configuracion, "busqueda_factura_corriente")) {
        return {
          status: false,
          message: "No tiene permiso para buscar facturas",
        };
      } else {
        const { resultListInvoice } = getState().facturacion;

        const body = {
          listado: resultListInvoice.map((item) => {
            return {
              ...item,
              monto: item?.monto?.replaceAll(".", ""),
              monto_abono: item?.monto_abono?.replaceAll(".", ""),
            };
          }),
          type,
          isViewDetail: !isViewDetail ? 0 : 1,
          title,
        };

        let response = await APIV1.putFile(endPoints.invoice.download, body);

        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = nameFile;
          a.click();
        });

        return { status: true, message: "Descargado con éxito" };
      }
    } catch (error) {
      return { status: false, message: "Hubo un problema, intente más tarde" };
    }
  };

export const searchDetailPayment =
  (payment_id) => async (dispatch, getState) => {
    try {
      const { configuracion } = getState().config;

      if (!validarPermiso(configuracion, "busqueda_factura_corriente")) {
        return {
          status: false,
          message: "No tiene permiso para buscar facturas",
        };
      } else {
        let response = await API.get(endPoints.invoice.getDocument(payment_id));
        const { status, message, data } = response.data;
        const payload = {
          data,
          listDocumentClient: [],
        };

        dispatch(requestSuccess(SEARCH_CLIENTE_PENDIENT, payload));

        return {
          status,
          message,
          data,
        };
      }
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const loadingPayment = (value) => (dispatch, getState) => {
  dispatch(requestSuccess(LOADING_SEARCH_PAYMENT, value));
};

export const resetDetailPayment = () => (dispatch, getState) => {
  const payload = {
    data: {
      full_name: "",
      rut: "",
      telephone: "",
      email: "",
      cancellation_date: new Date(),
      internal_code: "",
      payment_type: "Total",
      amount: 0,
      remaining_amount: 0,
      listDetailPayment: [],
      listDocumentPendient: [],
      total: 0,
    },
    listDocumentClient: [],
  };

  dispatch(requestSuccess(SEARCH_CLIENTE_PENDIENT, payload));
};

export const resetListInvoice = () => (dispatch, getState) => {
  const payload = {
    resultList: [],
    isLoading: false,
    dataSearch: {
      estatus_id: 22,
      from_date: new Date(),
      to_date: new Date(),
    },
  };

  dispatch(requestSuccess(SEARCH_INVOICE_BY_PARAMETERS, payload));
};

import { useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import ModalFactura from "components/Factura/ModalFactura";
import Cliente from "components/Encomienda/Entrega/Cliente";
import DetalleOrden from "components/Encomienda/Entrega/DetalleOrden";
import { UseDeliveryOrder } from "hooks/Orders/UseDeliveryOrder";

const EntregaDirecto = ({ toast }) => {
  const { state, actions, formElement } = UseDeliveryOrder();
  const { hidden, loading, disabledBtnPago } = state;

  const { getDatInitial } = actions;

  const {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  } = formElement;

  useEffect(() => {
    getDatInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form id="frmEntrega" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-1">
          <Col className="contenedor-cliente bg-soft-blue">
            <Form.Row>
              <Cliente
                register={register}
                getValues={getValues}
                setValue={setValue}
                toast={toast}
                disabledBtnPago={disabledBtnPago}
                control={control}
              />
            </Form.Row>
          </Col>
        </Row>

        <DetalleOrden
          register={register}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          toast={toast}
          loadingForm={loading}
          control={control}
        />
      </Form>
      {hidden && (
        <ModalFactura
          title={"FACTURA A NOMBRE DE:"}
          nameModulo="orden"
          stateOrder={state}
          actionsOrder={actions}
        />
      )}
    </>
  );
};

export default EntregaDirecto;

import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import TitlePrincipal from "components/Layouts/TitlePrincipal";
import GlobalSearch from "components/Orders/Consultas/GlobalSearch";
import PanelSeleccionado from "components/Orders/Consultas/PanelSeleccionado";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataInitialOrder,
  exportarExcel,
  exportPdf,
  resetSearchOrden,
  resetDataOrderCreate,
} from "actions/transportOrdersAction";
import { useHistory } from "react-router-dom";
import { getListOfficeAll } from "actions/admin/officeAction";
import { ToastContainer } from "react-toastify";

const SearchOrden = () => {
  const dispatch = useDispatch();

  const {
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
    loadingSearch,
  } = useSelector((state) => state.transportOrders);
  const { listOffices } = useSelector((state) => state.offices);

  const { disabledSearch, dataSearch } = useSelector((state) => state.orders);
  const history = useHistory();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  useEffect(() => {
    getDataInitial();
    dispatch(resetSearchOrden());
    dispatch(resetDataOrderCreate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataInitial = async () => {
    if (
      documentacionsCarga.length === 0 ||
      documentacionsPago.length === 0 ||
      documentacionsPagoSinB1.length === 0 ||
      tipoEnvios.length === 0 ||
      formaPagos.length === 0
    ) {
      await dispatch(getDataInitialOrder());
    }

    if (listOffices.length === 0) {
      await dispatch(getListOfficeAll("active"));
    }
  };

  const handleClick = async () => {
    setIsLoadingExcel(true); // Bloquear el botón
    try {
      await dispatch(exportarExcel(dataSearch));
    } catch (error) {
      console.error("Error al exportar el archivo:", error);
    } finally {
      setIsLoadingExcel(false); // Desbloquear el botón
    }
  };

  const handleExportPdf = async (event) => {
    setIsLoadingPdf(true);
    try {
      const response = await dispatch(exportPdf());
      if (response.status) {
        setIsLoadingPdf(false);
        history.push("/transport-orders/preview/file");
      } else {
        setIsLoadingPdf(false);
      }
    } catch (error) {
      console.error("Error al exportar el archivo:", error);
    } finally {
      setIsLoadingPdf(false); // Desbloquear el botón
    }
  };

  return (
    <Layout titlePanel="Consulta de Órdenes" modulo="orders_search">
      <TitlePrincipal title="Filtros" />
      <Row className="bg-gray panel-contenedor--info">
        <GlobalSearch loadingSearch={loadingSearch} />
      </Row>
      {!loadingSearch && (
        <Row>
          <Col sm={8} xs={12}>
            <PanelSeleccionado />
          </Col>
          <Col sm={4} xs={12}>
            <div className="mt-5 pt-4">
              <Button
                variant="warning"
                type="button"
                onClick={(event) => handleClick(event)}
                className="mr-1 pl-5 pr-5"
                disabled={disabledSearch || isLoadingExcel}
              >
                {isLoadingExcel ? "Espere..." : "Excel"}
              </Button>
              <Button
                variant="warning"
                type="button"
                onClick={(event) => handleExportPdf(event)}
                className="pl-5 pr-5"
                disabled={disabledSearch || isLoadingPdf}
              >
                {isLoadingPdf ? "Espere..." : "Imprimir"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <ToastContainer />
    </Layout>
  );
};

export default SearchOrden;

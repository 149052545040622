import { Fragment, useEffect } from "react";
import { Form, Col, Row, OverlayTrigger, InputGroup } from "react-bootstrap";
import TitlePrincipal from "components/Layouts/TitlePrincipal";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  handlePasteOnlyNumber,
  validateOnlyLetter,
  validateOnlyNumber,
  validateOnlyRUT,
  handlePasteOnlyLetter,
} from "utils/validationInput";
import { phone } from "phone";

const Remitente = ({
  formElement,
  ordenInfo,
  actions,
  state,
  validarRut,
  toast,
  setMessage,
  setHidden,
  popover,
  companyDataBasic,
  editOrden,
  getValues,
}) => {
  const { searchCustomerRemitente, setHabDestination, setHabRemitente } =
    actions;
  const { habDestination, habRemitente, habRazonSocial } = state;

  //const { register, errors, reset, getValues, setValue, control } = formElement;
  const { register, errors, setValue, control } = formElement;

  const onKeyDownCliente = async (event, type) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente(type);
    }
  };

  const searchClienteRemitente = async (type) => {
    let rut = document
      .getElementById(
        type === "remitente" ? "rut_remitente" : "rut_destinatario"
      )
      .value.toString()
      .toUpperCase();

    await searchCustomerRemitente(type, setValue, rut);
  };

  const handleBlurRut = (event) => {
    if (event === "") {
      setValue("razon_social_destinatario", "");
      setValue("direccion_destinatario", "");
      setValue("telefono_avisas", "");
      setHabDestination(true);
    }
  };

  const handleBlurRutRemitente = (event) => {
    if (event === "") {
      setValue("razon_social_remitente", "");
      setHabRemitente(true);
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  useEffect(() => {
    if (editOrden) {
      setHabDestination(false);

      if (getValues("telefono_avisas") !== "") {
        setValue("telefono_avisas", ordenInfo?.telefono_avisas);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOrden]);

  return (
    <Fragment>
      <Col md={6} sm={12} className="pr-4">
        <TitlePrincipal title="REMITENTE" classTitle="bg-gray-wran" />
        <Row className="bg-soft-blue ">
          <Col xs={8} className="mb-1">
            <Form.Group as={Col} controlId="rut_remitente" className="mb-0">
              <Form.Label>RUT</Form.Label>
              <OverlayTrigger placement="right" overlay={popover}>
                <Controller
                  autoComplete="off"
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control text-uppercase"
                      id="rut_remitente"
                      name="rut_remitente"
                      value={props.value}
                      maxLength={12}
                      //defaultValue={ordenInfo.rut_remitente}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                      onKeyDown={(event) =>
                        onKeyDownCliente(event, "remitente")
                      }
                      onKeyPress={(event) => validateOnlyRUT(event)}
                      onBlur={(event) =>
                        handleBlurRutRemitente(event.target.value)
                      }
                    />
                  )}
                  control={control}
                  name="rut_remitente"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
              </OverlayTrigger>
              {errors.rut_remitente && (
                <Form.Text className="text-danger">
                  {errors?.rut_remitente?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group
              as={Col}
              controlId="razon_social_remitente"
              className="mb-0"
            >
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                type="text"
                name="razon_social_remitente"
                className="text-uppercase"
                //defaultValue={ordenInfo.razon_social_remitente}
                readOnly={habRemitente}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onKeyPress={(event) => validateOnlyLetter(event)}
                maxLength={150}
                onPaste={(event) => handlePasteOnlyLetter(event)}
              />
              {errors.razon_social_remitente && (
                <Form.Text className="text-danger">
                  {errors?.razon_social_remitente?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col md={6} sm={12}>
        <TitlePrincipal title="DESTINATARIO" />
        <Row className="bg-soft-blue">
          <Col className="mb-0">
            <Form.Group
              as={Col}
              controlId="rut_destinatario"
              className="pr-0 pl-0 mb-1"
            >
              <Form.Label>RUT</Form.Label>
              <OverlayTrigger placement="right" overlay={popover}>
                <Controller
                  autoComplete="off"
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control text-uppercase"
                      id="rut_destinatario"
                      name="rut_destinatario"
                      value={props.value}
                      maxLength={12}
                      //defaultValue={ordenInfo.rut_destinatario}
                      onChange={(value) => {
                        props.onChange(value);
                      }}
                      //onBlur={(event) => handleBlurRut(event, setError)}
                      onKeyDown={(event) =>
                        onKeyDownCliente(event, "destinatario")
                      }
                      onKeyPress={(event) => validateOnlyRUT(event)}
                      onBlur={(event) => handleBlurRut(event.target.value)}
                    />
                  )}
                  control={control}
                  name="rut_destinatario"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
              </OverlayTrigger>
              {errors.rut_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.rut_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6} className="mb-0">
            <Form.Group
              as={Col}
              controlId="razon_social_destinatario"
              className="pr-0 pl-0 mb-1"
            >
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                type="text"
                name="razon_social_destinatario"
                className="text-uppercase"
                //defaultValue={ordenInfo.razon_social_destinatario}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                readOnly={habRazonSocial}
                onKeyPress={(event) => validateOnlyLetter(event)}
                maxLength={150}
                onPaste={(event) => handlePasteOnlyLetter(event)}
              />
              {errors.razon_social_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.razon_social_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group
              as={Col}
              controlId="direccion_destinatario"
              className="pr-0 pl-0"
            >
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                name="direccion_destinatario"
                readOnly={habDestination}
                className="text-uppercase"
                //defaultValue={ordenInfo.direccion_destinatario}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                maxLength={255}
                //onKeyPress={(event) => validateOnlyLetter(event)}
                //onPaste={(event) => handlePasteOnlyLetter(event)}
              />
              {errors.direccion_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.direccion_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group as={Col} controlId="telefono_avisas" className="pl-0">
              <Form.Label>Avisar a (N° teléfono)</Form.Label>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    {companyDataBasic?.country?.code}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="telefono_avisas"
                  readOnly={habDestination}
                  //defaultValue={ordenInfo.telefono_avisas || ""}
                  maxLength={15}
                  //disabled={companyDataBasic?.country?.code === "" ? true : false}
                  ref={register({
                    minLength: { value: 9, message: "Mínimo 9 dígitos" },
                    required: { value: true, message: "Requerido" },
                    //eslint-disable-next-line
                    pattern: {
                      value: /^([0-9]+)$/,
                      message: "Teléfono inválido",
                    },
                    validate: validatePhone,
                  })}
                  autoComplete="off"
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => handlePasteOnlyNumber(event)}
                />
              </InputGroup>

              {errors.telefono_avisas && (
                <Form.Text className="text-danger">
                  {errors?.telefono_avisas?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

export default Remitente;
